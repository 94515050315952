import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { connect } from 'react-redux';
import { Divider, Drawer } from 'antd';
import { isMobile } from '@zorba-shared/client/Services/clientService';

import { setModalState } from '../../../reducers/Modal/actions';
import { isHomeownerMode } from '../../../services/layoutService';
import { ZorbaButton } from '../../../components/forms';
import HeaderLinks from './Components/HeaderLinks';
import LoginButton from './Components/LoginButton';
import styles from './Header.module.scss';
import { BUTTON_VARIANT_DARK } from '../../../components/forms/ZorbaButton/constants';

const Header = (props) => {
  const appRoutes = {
    HOME: '/',
    SELLER_LEAD: '/seller-lead',
    DASHBOARD: '/dashboard',
    ABOUT_US: '/about-us',
    FAQ: '/faq',
    SELL: '/sell',
  };
  const { userDetails, isAuthLoading, isInit } = props;
  const { asPath: currentUrl, push } = useRouter();
  const pathName = currentUrl.includes('?')
    ? currentUrl.split('?')[0]
    : currentUrl;
  const homeownerMode = isHomeownerMode(pathName);
  const isDataMode = pathName.includes('data');
  const isHomeownerWizard =
    currentUrl.startsWith(appRoutes.SELLER_LEAD) ||
    currentUrl.startsWith(appRoutes.DASHBOARD);
  const isGuestMode = isInit && !isAuthLoading && !userDetails;
  const isFaqOrAboutUs =
    pathName === appRoutes.ABOUT_US || pathName === appRoutes.FAQ;
  const isHomeOwnerOnePager = pathName.startsWith('/sell');
  const isPartnerLandingPage = pathName.startsWith('/partner');

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileLayout, setIsMobileLayout] = useState(false);

  useEffect(() => {
    setIsMobileLayout(isMobile());
  }, []);

  if (isHomeownerWizard || isPartnerLandingPage) return null;


  return (
    <>
      <header className={styles['layout-header']}>
        <div className={styles['layout-header-inner']}>
          <div className={styles.left}>
            {isMobileLayout && (
              <div
                onClick={() => setIsSidebarOpen(true)}
                className={styles['drawer-menu']}
              >
                <i className="fas fa-bars" />
              </div>
            )}
            <Link href={appRoutes.HOME}>
              <div className={styles['logo-wrapper']}>
                <img
                  className={styles['mobile-logo']}
                  alt="Zorba"
                  src={
                    isMobileLayout
                      ? '/img/brand/zorba-icon.png'
                      : '/img/brand/zorba-logo-black.png'
                  }
                />
              </div>
            </Link>
          </div>
          <div className={styles.center}>
            {!isHomeownerWizard && (
              <HeaderLinks
                isGuestMode={isGuestMode}
                pageName={props.pageName}
              />
            )}
          </div>
          <div className={styles.right} id="header-right">
            {/* When user is checking properties as a guest */}
            {!homeownerMode ? (
              <LoginButton {...props} />
            ) : (
              <div className={styles['login-btn-empty-state']} />
            )}
            {homeownerMode && !isHomeownerWizard && !isFaqOrAboutUs && !isHomeOwnerOnePager && (
              <a
                className={styles['get-offers-btn']}
                href={isDataMode ? '#' : 'mailto:data@getzorba.com'}
              >
                <ZorbaButton onClick={() => {
                  if (isDataMode) {
                    window.scrollAndFocusHero();
                  }
                }} variant={BUTTON_VARIANT_DARK}>{isDataMode ? 'Get Sample Report' : 'Request a demo'}</ZorbaButton>
              </a>
            )}
            {isHomeOwnerOnePager && (
              <ZorbaButton
                fullWidth={false}
                onClick={() => {
                  push('/investors?utm_source=seo&utm_medium=sell');
                }}
              >
                Get offers
              </ZorbaButton>
            )}
          </div>
        </div>
      </header>
      {isMobileLayout && (
        <Drawer
          className={styles['header-drawer']}
          placement="left"
          closable
          onClose={() => setIsSidebarOpen(false)}
          open={isSidebarOpen}
        >
          <img
            className={styles['mobile-logo']}
            alt="Zorba"
            src="/img/brand/zorba-logo-black.png"
          />
          <Divider />
          <HeaderLinks isGuestMode={isGuestMode} pageName={props.pageName} />
        </Drawer>
      )}
    </>
  );
};
const mapStateToProps = ({ AuthReducer, PostReducer, ActivityFeedReducer }) => {
  const { userDetails, notificationList, isAuthLoading, isInit } =
    AuthReducer || {};
  const { post } = PostReducer || {};
  const { selectedPlace } = ActivityFeedReducer || {};
  return {
    userDetails,
    notificationList,
    post,
    isAuthLoading,
    isInit,
    selectedPlace,
  };
};
const mapDispatchToProps = {
  setModalState,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
